.intro-container {
  display: flex;
  flex-direction: row;
  height: 12vh;
  margin-bottom: 30px;
}

.intro-text {
    background: var(--box-bg-background);
    border-radius: var(--box-radius);
    box-shadow: 0 4px 8px var(--box-shadow-color);
    margin-top: 20px;
    transition: box-shadow 0.3s ease-in-out;
    color: var(--text-color-main);
    font-family: 'Helvetica Neue', sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  
  .intro-text:hover {
    box-shadow: 0 6px 12px var(--box-shadow-hover-color);
  }
  
  .intro-text p {
    line-height: 1.6;
  }