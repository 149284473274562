:root {
  --icon-hover-scale: 1.1;
  --icon-size: 90px;
  --icon-font-size: 0.75rem;
}

.icon-grid-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.padder {
  flex-grow: 1;
  height: 100%;
}

.icon-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(var(--max-columns), var(--icon-size)); /* Fixed number of columns */
  grid-gap: 1rem;
  margin: auto; 
  justify-content: flex-start; /* Align items to the start */
  overflow-x: auto; /* Horizontal scroll */
  padding-bottom: 10px;
}

@media (max-width: 600px), (max-height: 600px) {
  :root {
    --icon-size: 70px; /* Smaller icon size for smaller screens */
    --icon-font-size: 0.55rem;
  }

  .icon-grid {
    --max-columns: auto; /* Allow columns to adjust automatically */
    grid-gap: 0.5em;
  }
  
}