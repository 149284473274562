.details-box {
  background: var(--box-bg-background);
  border-radius: var(--box-radius);
  box-shadow: 0 4px 8px var(--box-shadow-color);
  padding: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  word-wrap: break-word;
  white-space: normal;
}

.details-box img {
  max-width: 90%;
  max-height: 20vw;
  width: 100%;
  height: auto; 
  object-fit: contain; /* Ensure aspect ratio */
  display: block; 
  margin: 0 auto;
  padding-top: 20px;
}

.details-box:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 6px 12px var(--box-shadow-hover-color);
}

.details-box h2 {
  color: var(--text-color-main);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.details-box p {
  color: var(--text-color-secondary);
  line-height: 1.6;
}

.details-box .link-box {
  display: inline-block;
  padding: 10px 15px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: var(--link-bg-color);
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.details-box a:hover {
  background-color: var(--link-bg-color-hover);
}

@media screen and (min-width: 1300px) {
  .details-box img {
    max-width: 70%;
  }
}