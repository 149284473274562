.video-frame {
    display: block;
    width: 100%; /* Responsive width */
    max-width: 560px; /* Maximum width */
    height: 315px; /* Fixed height, adjust as necessary */
    margin: 0 auto; /* Center the iframe */
    padding-top: 20px; /* Adds padding at the top */
  }

.embed-frame {
    padding-top: 20px;
}

p .cite-title {
    font-weight: bold;
    margin-bottom: -0.3rem;
    color: #333;
}

p .cite-authors {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #666;
}  