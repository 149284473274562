:root {
  --box-bg-background: linear-gradient(145deg, #ffffff, #ffffff); 
  --box-radius: 8px;
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --box-shadow-hover-color: rgba(0, 0, 0, 0.15);
  --text-color-main: #333;
  --text-color-secondary: #666;
  --link-bg-color: #007bff;
  --link-bg-color-hover: #0056b3; /* Darker blue on hover */
}

/* App.css */
.app {
  font-family: 'Arial', sans-serif;
  text-align: center;
  display: flex;
  padding: 20px;
  background: linear-gradient(60deg, #efeff2, #e9e8f1); /* Light grey background */
  padding-top: 0px;
  height: 100vh;
}


.icon-grids-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 20px;
}


.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  width: 60vw;
}

.icon-details {
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  background: #ffffff; /* White background for the details box */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.icon-details h3 {
  color: #333; /* Dark grey color for headings */
  margin-bottom: 8px;
}

.icon-details p {
  color: #666; /* Lighter grey for paragraph text */
  line-height: 1.6;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .icon-details {
    margin-top: 10px;
  }
}
