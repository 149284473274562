.contactbar-container {
  background: var(--box-bg-background);
  border-radius: var(--box-radius);
  box-shadow: 0 4px 8px var(--box-shadow-color);
  transition: box-shadow 0.3s ease-in-out;
  color: var(--text-color-main);
  font-family: 'Helvetica Neue', sans-serif;
  width: 25%;
  height: 100%;
  padding: 0.75%;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  overflow: hidden;
}

.contactbar-container:hover {
  box-shadow: 0 6px 12px var(--box-shadow-hover-color);
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.name-phd-location-container {
  flex-direction: column;
  flex-grow: 1;
  font-size: 0.8vw;
  margin-bottom: 0.3vw;
}

.headshot {
  border-radius: 50%;
  max-width: 50%;
  max-height: 100%;
  object-fit: contain;
}

.name {
  font-weight: bold;
  font-size: 1.2rem;
}

.phd-info {
  font-size: 0.7rem;
  margin-bottom: 0;
}

.location-info {
  font-size: 0.6rem;
  margin-bottom: 0rem;
}

.contact-icon-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.contact-icon {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
}

.contact-icon img {
  width: 70%;
  height: auto;
  color: #333; /* Icon color */
  display: flex;
}


/* Adjust the .contact-icon a styling if necessary */
.contact-icon a {
  width: 100%;
  height: 100%;
  color: inherit; /* Inherits color from parent */
  text-decoration: none;
}

.contact-icon:hover{
  filter:opacity(30%);
}

@media (max-width: 768px) {
  .name-phd-location-container {
    font-size: 1vw; /* Adjust based on current size */
  }

  .contactbar-container {
    flex-direction: column;
  }

  .phd-info,
  .location-info {
    font-size: calc(0.6 * 0.9rem); /* Example for specific elements */
  }

  .name-phd-location-container {
    display: none;
  }

  .info-container{
    height: 10%;
  }
}

@media (max-width: 480px), (max-height: 480px) {
  .name-phd-location-container {
    display: none;
  }
}

@media (max-width: 480px) {
  .contact-icon-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}