/* Icon.css */
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: linear-gradient(145deg, #f5f5f5, #e0e0e0);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: var(--icon-size);
  width: var(--icon-size);
  margin: 0.5rem;

  padding-top: 1rem;
  padding-bottom: 1rem;

}

.icon.active {
  /* Active styles, e.g., glow effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Example of a glow effect */
  transform: scale(var(--icon-hover-scale));
  background: linear-gradient(145deg, #72db96, #65cb78);
}

.icon:hover {
  transform: scale(var(--icon-hover-scale));
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.icon.active:hover {
  transform: scale(var(--icon-hover-scale));;
}

.icon-image {
  width: 80%;
  object-fit: contain;
  margin-bottom: 0.5rem;
  flex-grow: 1;
  min-height: 80%;
}

.icon-text {
  color: #333;
  font-size: var(--icon-font-size); 
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
}